import React from "react"
import { Helmet } from "react-helmet-async"
import { useLocation } from "@reach/router"
import isAbsoluteUrl from "is-absolute-url"
import urlJoin from "proper-url-join"

const fullURL = (url: string) => urlJoin(process.env.VERCEL_URL, url)

export const Seo = ({
  description,
  lang = "pl",
  meta,
  title,
  img,
  type
}: SEOProps) => {
  const { pathname } = useLocation()

  const imageURL = isAbsoluteUrl(img) ? img : fullURL(img)
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`IntoDiet | %s`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        // {
        //   name: `keywords`,
        //   content: site.keywords,
        // },
        {
          name: `image`,
          content: imageURL,
        },
        // ogp stuff
        {
          property: `og:url`,
          content: fullURL(pathname),
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: type,
        },
        {
          property: `og:image`,
          content: imageURL,
        },
        // twitter stuff
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          property: `twitter:image`,
          content: imageURL,
        },
      ].concat(meta || [])}
    />
  )
}

interface SEOProps {
  description?: string
  lang?: string
  meta?: [
    {
      name: string
      content: string
    }
  ]
  title: string
  img: string
  type?: string
}
